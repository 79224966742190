/* 验证码显示框 */
.numberBox {
	display: flex;
	justify-content: space-between;
}

/* 每一个验证码输入框的样式 */
.inputItem {
	margin-right: 4px;
	width: 26px;
	height: 32px;
	box-sizing: border-box;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #97a3b7;
	display: flex;
	justify-content: center;
	color: #2c69ff;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
}

/* 最后一个验证码输入框margin-right为0 */
.inputItem:last-child {
	margin-right: 0px;
}
.Input {
	font-size: 24px;
}

/* 将input输入框隐藏看不见 */
.inputValue {
	margin-left: -750px;
	position: absolute;
	z-index: -99;
	opacity: 0;
}
