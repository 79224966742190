.background {
	height: 100%;
	box-sizing: border-box;
	background-image: url("~@/assets/images/home/bg-home1.png");
	background-position: center center;
	background-size: cover;

	.topNumConchMessage {
		display: flex;
		width: 100%;
		box-sizing: border-box;
		padding: 18px 16px;
		color: #7ca9ff;
		justify-content: space-between;

		font-size: 14px;
		.leftLogo {
			width: 88px;
			height: 19px;
		}
	}
	.buttonBox {
		position: fixed;
		left: 0px;
		bottom: 50px;
		display: flex;
		width: 100%;
		box-sizing: border-box;
		padding: 0 16px;
		justify-content: space-between;
		.throwConch {
			width: 164px;
			height: 40px;
			background: linear-gradient(187deg, #c92dfe 0%, #5925ff 100%);
			border-radius: 20px;
			border: 2px solid #eca2ff;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 16px;
			img {
				margin-right: 8px;
				width: 25px;
				height: 24px;
			}
		}
		.catchConch {
			width: 164px;
			height: 40px;
			background: linear-gradient(184deg, #1aa0ff 0%, #2f35ff 100%);
			border-radius: 20px;

			border: 2px solid #7ca9ff;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 16px;
			img {
				margin-right: 8px;
				width: 25px;
				height: 24px;
			}
		}
	}
}
