.background {
	width: 100vw;
	height: 100%;
	box-sizing: border-box;
	background: url("~@/assets/images/home/bg-home2.png");
	background-position: center center;
	background-size: cover;
	.backgroundbg {
		width: 100vw;
		height: 100vh;
		background: #000;
		opacity: 0.5;
		position: fixed;
		left: 0px;
		top: 0px;
	}
	.topNumConchMessage {
		display: flex;
		width: 100%;
		box-sizing: border-box;
		padding: 18px 16px;
		color: #7ca9ff;
		justify-content: space-between;

		font-size: 14px;
		.leftLogo {
			width: 88px;
			height: 19px;
		}
	}

	.buttonBox {
		position: absolute;
		left: 0px;
		bottom: 74px;
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: column;
		color: #fff;
		.conchNumberText {
			display: flex;
			align-items: center;
		}
		.eye {
			z-index: 10;
			color: #bcc4d0;
			position: absolute;
			top: 14px;
			right: 16px;
		}
		.Input {
			position: relative;
			display: flex;
			align-items: center;
			width: 335px;
			height: 48px;
			box-sizing: border-box;
			background: #ffffff;
			border-radius: 24px;
			border: 2px solid rgba(44, 105, 255, 0.45);
			margin: 16px 0 32px 0;
			:global .adm-input-element {
				text-align: center;
				height: 100%;
			}
		}
		.loginBtn {
			width: 335px;
			height: 48px;
			box-sizing: border-box;
			background: linear-gradient(187deg, #c92dfe 0%, #5925ff 100%);
			border-radius: 24px;
			border: 2px solid #eca2ff;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 16px;
		}
		img {
			margin: 0 4px;
			width: 16px;
			height: 16px;
		}
	}
}
