.itemBox {
	width: 100%;
	height: 108px;
	box-sizing: border-box;
	background: #ffffff;
	border-radius: 12px;
	border: 2px solid rgba(44, 105, 255, 0.45);
	margin-top: 20px;
	padding: 8px 12px;
	display: flex;
	.img {
		width: 40px;
		height: 40px;
		box-sizing: border-box;
		margin-right: 16px;
	}
	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		.time{
			font-size: 14px;
			color: #000000a6;
			margin-bottom: 4px;
		}
		.text {
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			display: -moz-box;
			-moz-line-clamp: 2;
			-moz-box-orient: vertical;
			overflow-wrap: break-word;
			word-break: break-all;
			white-space: normal;
			overflow: hidden;
			line-height: 22px;
			font-size: 14px;
		}
		.contentBottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.replyBtn {
			width: 50px;
			height: 28px;
			box-sizing: border-box;
			border-radius: 4px;
			border: 1px solid #2c69ff;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #2c69ff;
			font-size: 14px;
		}
		.numReply {
			color: #bcc4d0;
		}
	}
}
