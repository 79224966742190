.couchKeybg {
	width: 100vw;
	height: 100vh;
	background: #000;
	opacity: 0.5;
	position: fixed;
	left: 0px;
	top: 0px;
}
.couchKey {
	background-image: url("~@/assets/images/common/modal-bg.png");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 220px;
	height: 300px;
	box-sizing: border-box;
	position: absolute;
	left: 50%;
	top: 44%;
	transform: translate(-50%, -50%);
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	.centerVertify {
		margin: 28px 0;
	}
	.couchKeyTitle {
		margin-top: 47px;
		font-size: 14px;
		color: #4b5b76;
		line-height: 22px;
	}
	.lookReply {
		font-size: 12px;
		line-height: 18px;

		color: #5f7292;
	}
}
