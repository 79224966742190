.contentWrap {
	height: 100%;
	background-image: url("~@/assets/images/common/bg-common1.png");
	background-position: 50%;
	background-size: 100% 100%;
	background-size: cover;
	padding: 60px 20px 60px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	::-webkit-input-placeholder{
		font-size: 14px;
	}
	.backHome{
		position: absolute;
		left: 0;
		top: 8px;
		right: 0;
	}
	.questionWrap {
		flex: 1;
		min-height: 150px;
		background: #ffffff;
		border-radius: 16px 48px 16px 16px;
		border: 4px solid #1b49a2;
		position: relative;
		padding: 12px 12px 22px;
		display: flex;
		flex-direction: column;
		.waveImg {
			width: 36%;
			position: absolute;
			right: -12px;
			top: -35px;
		}
		.questionTop {
			font-size: 14px;
			color: #4b5b76;
			line-height: 22px;
			margin-bottom: 12px;
		}
		.questionContent {
			flex: 1;
			:global .adm-text-area-element{
				background: #f1f7ff;
				display: inline-block;
				height: 100%;
				padding: 8px;
				border-radius: 8px;
				font-size: 14px;
			}
		}
		:global .adm-text-area-count{
			color: #00000073;
			line-height: 18px;
			font-size: 12px;
			padding-top: 0;
			padding-right: 4px;
		}
	}
	.captchaInputWrap{
		height: 52px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		border-radius: 12px;
		border: 4px solid #1b49a2;
		background-color: #fff;
		margin: 20px 0 12px;
		padding-right: 8px;
	}
	.captchaInput {
		:global .adm-input-element{
			height: 100%;
			padding: 0 12px;
			font-size: 14px;
		}
		&:hover{
			border-inline-end-width:4px;
			border-color:#1b49a2;
		}
	}
	.captchaChangeBtn {
		font-size: 14px;
		color: #ffffff;
		line-height: 20px;
		text-align: right;
	}
	.throwBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 48px;
		box-sizing: border-box;
		background: linear-gradient(270deg, #c92dfe 0%, #5925ff 100%);
		border-radius: 24px;
		border: 2px solid #eca2ff;
		font-size: 18px;
		margin: 0 auto;
		margin-top: 24px;
		.throwBtnText {
			margin-left: 8px;
			color: #fff;
		}
	}
}

// 回复码弹窗
.replyModal {
	top: 15%;
	:global{
		.adm-center-popup-mask {
			background-color: rgba(0, 0, 0, 0.65);
		}
		.adm-center-popup-body{
			background-color: transparent;
			box-shadow: none;
		}
	}
	
	.replyContent {
		box-sizing: border-box;
		width: 200px;
		height: 320px;
		background-image: url("~@/assets/images/common/modal-bg.png");
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
		text-align: center;
		margin: 0 auto;
		font-size: 14px;
		padding-top: 55px;
		position: relative;
		.replyCloseBtn {
			position: absolute;
			top: 12px;
			right: 12px;
		}
		.replyTitle {
			line-height: 20px;
			font-weight: 600;
			color: #4b5b76;
		}
		.replyCode {
			font-size: 36px;
			font-weight: bold;
			color: #2c69ff;
			line-height: 46px;
			margin-top: 16px;
		}
		.replyCheckBtn {
			font-size: 12px;
			color: #2c69ff;
			line-height: 17px;
			margin-top: 20px;
		}
		.replyTips {
			color: #4b5b76;
			line-height: 17px;
			font-size: 12px;
			margin-top: 16px;
		}
	}
}
