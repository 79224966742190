.scrollableDiv {
	height: 100vh;
	box-sizing: border-box;
	overflow: auto;
	padding: 50px 16px 0;
	background: url("~@/assets/images/common/bg-common2.png") no-repeat;
	background-size: 100% 100%;
	background-attachment: fixed;
}
.editPassword {
	font-size: 18px;
	color: #333;
	position: absolute;
	right: 15px;
	display: flex;
	justify-content: center;
	top: 16px;
	z-index: 999;
}
.tabs {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	box-sizing: border-box;
	background-color: #fff;
	z-index: 100;
	:global {
		.adm-tabs-tab-wrapper {
			height: 48px;
			line-height: 34px;
		}
		.adm-tabs-tab-line {
			height: 3px;
			bottom: 2px;
			border-radius: 8px;
			border-color: #2c69ff;
		}
	}
}
:global {
	.ant-tabs-nav-list {
		width: 100%;
	}
	.ant-tabs-tab {
		flex: 1;
		display: flex;
		justify-content: center;
		text-align: center;
	}
	.ant-tabs-nav {
		margin-bottom: 0px !important;
	}
	.ant-divider-inner-text {
		color: #fff;
	}
}
