.contentWrap {
	height: 100%;
	box-sizing: border-box;
	overflow-y: scroll;
	background-image: url("~@/assets/images/common/bg-common2.png");
	background-position: 50%;
	background-size: 100% 100%;
	background-size: cover;
	padding: 18px 20px 80px;
	color: #fff;
	font-size: 14px;
	.topWrap {
		display: flex;
		justify-content: space-between;
		position: fixed;
		top: 12px;
		left: 0;
		right: 0;
		z-index: 10;
	}
	.mainWrap {
		position: relative;
		z-index: 1;
		// 问题区
		.questionWrap {
			margin-top: 48px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			&.questionWrapReply {
				margin-top: 0;
				flex-direction: row-reverse;
				.content{
					margin-left: 16px;
				}
				.questionTitle {
					margin-right: 0;
					border-radius: 2px 12px 12px 12px;
				}
			}
			.questionTitle {
				background: #fff;
				border-radius: 12px 2px 12px 12px;
				border: 2px solid #1b49a2;
				color: #4b5b76;
				line-height: 22px;
				margin-right: 16px;
				margin-top: 4px;
				padding: 8px;
				word-break: break-all;
				max-height: 40vh;
				overflow-y: scroll;
				white-space:pre-line;
			}
		}
		// 回复列表区
		.relyItemWrap {
			display: flex;
			align-items: flex-start;
			margin-top: 16px;
			.replyItem {
				margin-left: 16px;
				.replyItemName {
					line-height: 20px;
				}
				.replyItemContent {
					word-break: break-all;
					margin-top: 8px;
					padding: 8px;
					color: #4b5b76;
					line-height: 22px;
					max-height: 40vh;
					overflow-y: scroll;
					background: #fff;
					border-radius: 2px 12px 12px 12px;
					border: 2px solid #1b49a2;
					white-space:pre-line;
				}
			}
		}
		.avatar {
			flex-shrink: 0;
			width: 40px;
			height: 40px;
			box-sizing: border-box;
			border-radius: 50%;
			overflow: hidden;
		}
		.noReply {
			margin-top: 120px;
			color: #7787a2;
			line-height: 22px;
			text-align: center;
		}
		// 回复输入区
		.replyInputWrap {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 5px 8px 20px;
			min-height: 56px;
			box-sizing: border-box;
			background: #ffffff;
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			.replyInput {
				background: #ebedf1;
				border: none;
				border-radius: 8px;
				overflow: hidden;
				:global {.adm-text-area-element{
					background: #EBEDF1;
					min-height: 40px;
					box-sizing: border-box;
					padding: 8px 12px;
				}
			}
			}
			.replySendBtn {
				flex-shrink: 0;
				font-size: 16px;
			}
		}
	}

	// 空状态
	.mainEmpty {
		margin-top: 150px;
		text-align: center;
	}
}
